<template>
  <!--begin::Signin-->
  <div class="login-form login-signin h-100 d-flex flex-column justify-content-between">
    <div class="mb-10 mb-lg-0 mt-lg-25">
      <img src="/image/side-logo.png"
           class="mb-10"
           alt="logo" />
      <h3 class="font-size-h1 text-bold">
        {{ $ml.get(`welcomeTo${this.appName()}`) }}
      </h3>
      <p class="text-muted font-size-secondary font-weight-semi-bold">
        {{ $ml.get('newHere') }}
        <router-link to="/register"
                     class="text-bold font-size-3 ml-2">
          {{ $ml.get('createAnAccount') }}
        </router-link>
      </p>
    </div>

    <!--begin::Form-->
    <div class="form">
      <div class="login-with-plusclouds mb-10">
        <a :href="loginUrl"
           class="btn btn-primary w-100 text-bold">
          {{ $ml.get(`loginWith${this.appName()}`)}}
        </a>
      </div>
    </div>
    <!--end::Form-->

    <AuthFooter />
  </div>
  <!--end::Signin-->
</template>

<script>
import { mapGetters } from 'vuex'
import AuthFooter from '@/components/auth/AuthFooter'

export default {
  name: 'login',
  mounted() {
    window.location.href = this.loginUrl
  },
  computed: {
    ...mapGetters(['prodEnv']),
    loginUrl() {
      let language = this.$ml.current
      if (!language) language = process.env.VUE_APP_DEFAULT_LANGUAGE
      return `${process.env.VUE_APP_LOGIN_URL}v2/oauth/authorize?client_id=${process.env.VUE_APP_CLIENT_ID}&redirect_uri=${process.env.VUE_APP_REDIRECT_URI}&response_type=code&scope=&locale=${language}`
    },
  },
  methods: {
    appName() {
      return process.env.VUE_APP_NAME
    },
  },
  components: {
    AuthFooter,
  },
}
</script>
